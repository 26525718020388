import { publicConfig } from 'config'
import type { AppEvent } from 'shared-code/app-events'
import { MapAppEventListeners } from 'shared-code/app-events-listeners'
import { gtmDatalayer } from 'shared-code/gtm'

export const appEventsListenersSG: Partial<MapAppEventListeners<AppEvent, 'event', void>> = {
  'discovery-sort-changed': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Facet Clicked',
        properties: {
          button_type: 'Sorting Tool',
          button_use: e.field,
          cta: e.order === 'asc' ? 'Low to High' : 'High to Low',
          destination_url: normalizeUrl(e.url),
        },
      })
    },
  ],
  'poll-login-triggered': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Poll login triggered',
        properties: {
          url: normalizeUrl(e.url),
          poll_question: e.question,
        },
      })
    },
  ],
  'user-authenticated': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'User Authenticated',
        properties: {
          url: normalizeUrl(e.url),
        },
      })
    },
  ],
  'poll-voted': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Poll Response',
        properties: {
          url: normalizeUrl(e.url),
          poll_question: e.question,
          poll_answers: e.answer,
          current_responses: e.responsesCount,
        },
      })
    },
  ],
  'product-list-viewed': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Product List Viewed',
        properties: {
          products: e.products.map((p, i) => ({
            product_id: p.id,
            sku: p.title,
            brand: p.brand,
            name: p.title,
            feature_tag: p.tags.map(t => t.label).join(', '),
            msrp: p.msrp,
            soundguys_rating: p.rating.our?.score,
            position: i + 1,
            category: p.category,
            url: normalizeUrl(p.button.pLink.href),
            image_url: p.image?.src,
          })),
        },
      })
    },
  ],
  'product-list-clicked': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Product Clicked',
        properties: {
          category: e.product.category,
          product_id: e.product.id,
          sku: e.product.title,
          brand: e.product.brand,
          name: e.product.title,
          feature_tag: e.product.tags.map(t => t.label).join(', '),
          cta: 'Check price',
          click_url: normalizeUrl(e.product.button.pLink.href),
          msrp: e.product.msrp,
          url: normalizeUrl(e.product.button.pLink.href),
          image_url: e.product.image?.src,
        },
      })
    },
  ],
  'newsletter-signup': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'List Signup',
        properties: {
          url: normalizeUrl(e.url),
          cta: e.label,
          heading: e.heading,
          email: e.email,
        },
      })
    },
  ],
  'widget-interaction': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Widget Clicked',
        properties: {
          widget_type: e.widgetType,
          cta: e.label,
          destination_url: normalizeUrl(e.url),
        },
      })
    },
  ],
  'page-article-viewed': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Article Viewed',
        properties: {
          url: normalizeUrl(e.url),
          category: e.categoryTitle,
          category_level: e.categoryLevel,
          author: e.author,
          published_date: e.date,
          title: e.title,
          word_count: e.wordCount,
        },
      })
    },
  ],
  'page-category-viewed': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Category Viewed',
        properties: {
          url: normalizeUrl(e.url),
          category: e.title,
          category_level: e.level,
        },
      })
    },
  ],
  'page-tag-clicked': [
    e => {
      if (e.type === 'top-category') {
        void gtmDatalayer({
          type: 'track',
          event: 'Link Clicked',
          properties: {
            link_type: 'Category',
            cta: e.label,
            destination_url: normalizeUrl(e.url),
            location: 'Top of Post',
          },
        })
      }
      if (e.type === 'bottom-category') {
        void gtmDatalayer({
          type: 'track',
          event: 'Link Clicked',
          properties: {
            link_type: 'Category',
            cta: e.label,
            destination_url: normalizeUrl(e.url),
          },
        })
      }
      if (e.type === 'tag') {
        void gtmDatalayer({
          type: 'track',
          event: 'Link Clicked',
          properties: {
            link_type: 'Tag',
            cta: e.label,
            destination_url: normalizeUrl(e.url),
            location: 'Bottom of Post',
          },
        })
      }
    },
  ],
  'discovery-changed': [
    e => {
      switch (e.action) {
        case 'additional-added':
        case 'additional-removed':
          void gtmDatalayer({
            type: 'track',
            event: 'Facet Clicked',
            properties: {
              button_type: 'Facet Selector',
              button_use: 'Feature Filter',
              cta: e.label,
              destination_url: normalizeUrl(e.url),
            },
          })
          break
        case 'brand-added':
        case 'brand-removed':
          void gtmDatalayer({
            type: 'track',
            event: 'Facet Clicked',
            properties: {
              button_type: 'Facet Selector',
              button_use: 'Brand Selection',
              cta: e.label,
              destination_url: normalizeUrl(e.url),
            },
          })
          break
        case 'clear':
          void gtmDatalayer({
            type: 'track',
            event: 'Facet Clicked',
            properties: {
              button_type: 'Facet Selector',
              button_use: 'Clear Selections',
              cta: e.label,
              destination_url: normalizeUrl(e.url),
            },
          })
          break
        case 'price':
          void gtmDatalayer({
            type: 'track',
            event: 'Facet Clicked',
            properties: {
              button_type: 'Facet Selector',
              button_use: 'Price Range',
              cta: e.label,
              destination_url: normalizeUrl(e.url),
            },
          })
          break
        case 'rating-added':
        case 'rating-removed':
          void gtmDatalayer({
            type: 'track',
            event: 'Facet Clicked',
            properties: {
              button_type: 'Sort',
              button_use: 'Ratings',
              cta: e.label,
              destination_url: normalizeUrl(e.url),
            },
          })
          break
        case 'rating-value':
          void gtmDatalayer({
            type: 'track',
            event: 'Facet Clicked',
            properties: {
              button_type: 'Facet Selector',
              button_use: 'Rating Slider',
              cta: e.label,
              destination_url: normalizeUrl(e.url),
            },
          })
          break
      }
    },
  ],
  'top-menu-tab-clicked': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Button Clicked',
        properties: {
          button_type: 'Tab',
          button_use: 'Navigation',
          location: 'Top Menu',
          cta: e.label,
          destination_url: normalizeUrl(e.url),
        },
      })
    },
  ],
}

function normalizeUrl(url?: string): string {
  // if relative url, prepend with current domain
  if (url && !url.startsWith('http')) {
    return `${publicConfig('appUrl')}${url}`
  }
  return url ?? ''
}
