import type { AppProps } from 'next/app'
import Head from 'next/head'
import { gtmDatalayer } from 'shared-code/gtm'
import TakeoverScript from 'shared-components/ad/TakeoverScript'
import 'shared-components/styles/globals.css'
import 'shared-components/styles/auth0.css'
import 'shared-components/styles/preflight.css'
import stylesUtils from 'shared-components/styles/util.module.css'
import { useAttributedWebVitals } from 'shared-components/hooks/use-attributed-web-vitals'
import { AuthContext } from 'shared-components/contexts/AuthContext'
import { publicConfig } from 'config'
import { NotificationsContext } from 'shared-components/contexts/NotificationsContext'
import { appEventListeners, mergeAppEventsListeners } from 'shared-code/app-events-listeners'
import { appEventsListenersSG } from '../app-events-listeners-sg'
import { AppEventsContext } from 'shared-components/contexts/AppEventsContext'

const eventListeners = {
  listeners: mergeAppEventsListeners(appEventListeners, appEventsListenersSG),
}

const SoundGuysApp: React.VFC<AppProps> = ({ Component, pageProps }) => {
  // references
  // https://github.com/google-marketing-solutions/web-vitals-gtm-template
  // https://github.com/GoogleChrome/web-vitals?tab=readme-ov-file#report-only-the-delta-of-changes
  useAttributedWebVitals(metric => {
    void gtmDatalayer({
      event: 'web_vitals',
      webVitalsData: { ...metric, entries: null },
    })

    // to custom metric
    // void fetch('https://wwsg1.aktee.top:6510/metric', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     id: metric.id,
    //     client: detectDeviceType(),
    //     uri: window.location.pathname,
    //     name: metric.name,
    //     value: metric.value,
    //     target: metric.target,
    //     rating: metric.rating,
    //   }),
    // })
  })

  return (
    <>
      <Head>
        <TakeoverScript className={stylesUtils.takeoverOn} />
      </Head>
      <NotificationsContext.Provider>
        <AppEventsContext.Provider initialState={eventListeners}>
          <AuthContext.Provider
            initialState={{
              serviceUrl: publicConfig('authService'),
              clientId: publicConfig('auth0ClientId'),
              domain: publicConfig('auth0Domain'),
            }}
          >
            <Component {...pageProps} />
          </AuthContext.Provider>
        </AppEventsContext.Provider>
      </NotificationsContext.Provider>
    </>
  )
}

export default SoundGuysApp

// function detectDeviceType(): string {
//   const userAgent = navigator.userAgent || navigator.vendor
//   const screenWidth = window.innerWidth

//   const mobileRegex =
//     /android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos/i

//   if (mobileRegex.test(userAgent) || screenWidth <= 800) {
//     return 'Mobile'
//   }
//   return 'Desktop'
// }

// export function reportWebVitals({ id, label, value, name }: NextWebVitalsMetric): void {
//   void gtmDatalayer({
//     event: 'web-vitals',
//     event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
//     event_action: name,
//     // Google Analytics metrics must be integers, so the value is rounded.
//     // For CLS the value is first multiplied by 1000 for greater precision
//     // (note: increase the multiplier for greater precision if needed).
//     event_value: Math.round(name === 'CLS' ? value * 1000 : value),
//     // The 'id' value will be unique to the current page load. When sending
//     // multiple values from the same page (e.g. for CLS), Google Analytics can
//     // compute a total by grouping on this ID (note: requires `eventLabel` to
//     // be a dimension in your report).
//     event_label: id,
//     non_interaction: true, // avoids affecting bounce rate.
//   })
// }
